/* === ProductCard === */

.product-card * {
    font-family: Oswald, sans-serif;
}
.product-card h3 {
    color: #000;
    text-transform: none;
    margin-top: 10px;

    font-size: 28px;
    text-align: center;
    font-weight: bold;
    font-family: Oswald, sans-serif;
}
.vcenter {
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.product-card p {
    color: #000;
    text-align: left;
    margin: 0;
}
.product-card .row img {
    margin: 0 auto;
    display: block;
}
.product-card .price-unit,
.product-card .price-group {
    position: relative;
    z-index: 2;
}
.product-card .price-unit .price,
.product-card .price-group .price {
    position: absolute;
    z-index: 2;
    right: 10px;
    line-height: 70px;
    margin: 0;
    font-size: 30px;
    font-weight: bold;
}
.product-card .price-unit .price span,
.product-card .price-group .price span {
    font-size: 16px;
    color: #000;
}


/* === ProductCard-Modal === */

.custom-modal .btn:hover {
    transform: scale(1.1);
}

.custom-modal .product-image {
    width: 300px;
    height: 300px;
    object-fit: contain;
}
.custom-modal h3 {
    font-family: Oswald, sans-serif;
    text-align: center;
}
.custom-modal .row,
.custom-modal .row p {
    margin: 0;
    font-family: Oswald, sans-serif;
    font-size: 14px;
}
.custom-modal input {
    text-align: right;
}

p.description {
     position: relative;
     padding-bottom: 20px;
}

p.description span{
    position: absolute;
    top: 58px;
    right: 0;
    width: 100%;
    height: 20px;
    background: white;
    onclick: "test()";
}

.product { position: relative; }
.product_picto { position: absolute; z-index: 1 }
.product_picto img { max-height: 50px; }

.popup__body figure>img {
    display: block;
    margin: 30px auto;
}
.popup__body h4 { text-align: center; }

@media (max-width: 768px) { 
    .product_picto img { max-height: 35px; }
}


/* === MenuProduct modal === */

.modifier-popup__wrap {
    width: 525px;
    max-width: 100%;
    margin: auto;
    z-index: 1050;
    padding: 0.75rem 2rem;
}

.modifier-popup__close {
    position: absolute;
    right: 0.75rem;
    width: 36px;
    height: 36px;
    padding: 0;
    display: inline-block;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modifier-popup__close:before {
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.modifier-popup__close:after {
    height: 100%;
    width: 2px;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.modifier-popup__close:before, .modifier-popup__close:after {
    content: '';
    position: absolute;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    border-radius: 2px;
    background-color:#576fa1;
}
.popup__body figure>img {
    display: block;
    margin: 30px auto;
}
.popup__body h4 { text-align: center; }